import { useQuery } from "@tanstack/react-query"
import { Loading } from "../../../Components/Screen/Loading/Loading"
import { CircleLight } from "../../../Components/Screen/circleLight"
import SimpleFooter from "../../../Components/SimpleFooter/Index"
import { InfosNFTComponent } from "./Infos"
import { QrCodeNftComponent } from "./QRCode"
import { getNftById } from "../../../utils/web3/getNftById-alchemy"
import axios from "axios"
import { getNftByIdDb, getUserByNftId, removeZeroFieldsFromUri, saveNft, saveTransactionHash, saveUri } from "../../../utils/firebase/nfts"
import { useState } from "react"
import { ArrowsCounterClockwise } from "phosphor-react"
import { refreshNFTMetadata } from "../../../utils/web3/refreshNftMetadata"
import { toast } from "react-toastify"
import { getTransactionHashByTokenId } from "../../../utils/web3/getTransactionHashByTokenId"
import { mapLocation } from "../locations"
import { MapContainer } from "./MapContainer"
import WineMap from "./WineMap"

export default function NFTViewPage() {
    const params = window.location.pathname.split('/')
    const tokenId = params[params.length - 1]
    const [tryNftDb, setTryNftDb] = useState(false)
    const [token_uri, setTokenUri] = useState('')
    const [nft, setNft] = useState({})
    const [hash, setHash] = useState({})

    const { data: nftDb } = useQuery({
        queryKey: ['nftDb'],
        queryFn: () =>
            getNftByIdDb(tokenId).then((nft) => {
                nft?.raw?.tokenUri && setTokenUri(nft?.raw?.tokenUri)
                nft?.metadata && setNft(nft.metadata)
                !nft?.hash?.transaction_hash && getTransactionHashByTokenId({ token_id: tokenId }).then(async (hash) => {
                    setHash(hash)
                    await saveTransactionHash(tokenId, hash)
                })
                nft?.hash?.transaction_hash && setHash(nft.hash)
                return nft
            }).finally(() => setTryNftDb(true))
        ,
        enabled: !!tokenId,
        refetchOnWindowFocus: false,
        refetchInterval: false,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
    })

    useQuery({
        queryKey: ['nftWeb'],
        queryFn: () =>
            getNftById({ token_id: tokenId })
                .then(async (nft) => {
                    setTokenUri(nft?.raw?.tokenUri)
                    await saveNft(nft)
                    return nft
                })
        ,
        enabled: !!tokenId && !nftDb?.name && tryNftDb,
        refetchOnWindowFocus: false,
        refetchInterval: false,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
    })

    useQuery({
        queryKey: ['nft'],
        queryFn: () =>
            axios(token_uri).then(async (response) => {
                const responseFormatted = removeZeroFieldsFromUri(response.data)
                await saveUri(tokenId, response.data)
                setNft(responseFormatted)
                getTransactionHashByTokenId({ token_id: tokenId }).then(async (hash) => {
                    setHash(hash)
                    await saveTransactionHash(tokenId, hash)
                })
                return responseFormatted
            })
        ,
        enabled: !!token_uri && !nftDb?.metadata?.name && tryNftDb,
        refetchOnWindowFocus: false,
        refetchInterval: false,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
    })

    const { data: userNft } = useQuery({
        queryKey: ['userNft'],
        queryFn: () =>
            getUserByNftId(tokenId).then((nft) => {
                return nft
            })
        ,
        enabled: !!tokenId,
        refetchOnWindowFocus: false,
        refetchInterval: false,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
    })

    const image = nft?.image || "https://images.unsplash.com/photo-1553361371-9b22f78e8b1d?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHdpbmV8ZW58MHx8MHx8fDA%3D"

    async function refreshMetadata() {
        await refreshNFTMetadata({ token_id: tokenId })
        toast('Foi solicitado a atualização dos dados no blockchain, aguarde alguns minutos e retorne/atualize a página.', {
            type: 'success',
        })
    }

    if (tokenId == 4) {
        //redirect to nft 22
        window.location.href = '/nft/22'
    }
    if (tokenId == 1) {
        //redirect to nft 23
        window.location.href = '/nft/23'
    }
    if (!nft?.name) return <Loading />

    const location = userNft?.name && mapLocation(userNft?.name)
    return (
        <main className="bg-preto min-h-[calc(100vh_-_80px)] flex justify-between flex-col relative p-4">
            <CircleLight position={' top-0 left-0'} />
            <CircleLight position={' top-0 right-0'} />
            <CircleLight position={' bottom-[30%] left-0'} />
            <CircleLight position={' bottom-[30%] right-0'} />

            <div className="flex flex-col space-y-6 justify-center items-center w-full">
                <div className="flex flex-col space-y-2">
                    <div className="flex self-end mr-2 items-center space-x-4" title="Clique para atualizar os dados caso não estejam aparecendo.">
                        <ArrowsCounterClockwise
                            size={32}
                            className="cursor-pointer"
                            onClick={refreshMetadata}
                        />
                    </div>
                    <img src={image}
                        alt=''
                        className="w-full max-w-2xl max-h-dvh object-fill object-center rounded-3xl cursor-pointer mb-3 select-none"
                        title='Clique para ampliar o certificado em outra aba'
                        onClick={
                            () => window.open(image, "_blank")
                        }
                        loading="eager"
                    />
                </div>
                {tokenId && <WineMap nftId={Number(tokenId)} />}
                <InfosNFTComponent nft={nft} token_id={tokenId} hash={hash} />
                <QrCodeNftComponent token_id={tokenId} />
                {location?.lng && location?.lat &&
                    <div className="w-full max-w-[300px]">
                        <h1 className="text-white text-2xl">Localização da vinícola</h1>
                        <MapContainer location={location} />
                    </div>
                }

                <SimpleFooter />
            </div>
        </main >
    )
}
